import { FormValues, formSchema } from "./schema";
import { CourierFormComponentProps } from "../types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "easyship-components";
import { Form } from "@/components/Form";
import { PdfAgreement } from "./pdfAgreement";
import { useState } from "react";
import { ControlledInput } from "@/components/ControlledInput";
import { Separator } from "@/components/Separator";
import { useCountries } from "@/hooks/useCountries";
import { ControlledSelect } from "@/components/ControlledSelect";

export const COMPATIBLE_UMBRELLA_NAMES = {
  fedex: "FedEx",
  fedexCrossBorder: "FedEx Cross Border",
  fedexParcelcast: "FedEx-Parcelcast",
} satisfies Record<string, string>;

export const FormComponent = ({
  onSuccess,
}: CourierFormComponentProps<FormValues>) => {
  // TODO: we need to fetch address data and prefill the form
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });
  const { data: countries } = useCountries();

  const acceptAgreement = (accept: boolean) => {
    setIsAgreementAccepted(accept);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <p>
        By connecting your FedEx account, you will no longer be eligible to use
        the discounted FedEx rates offered by Easyship.
      </p>
      <Separator />
      {!isAgreementAccepted ? (
        <PdfAgreement
          umbrellaName={COMPATIBLE_UMBRELLA_NAMES.fedex} // Cross Border is not implemented yet
          acceptAgreement={() => acceptAgreement(true)}
        />
      ) : (
        <Form
          onGoBack={() => acceptAgreement(false)}
          onSubmit={handleSubmit(onSuccess)}
        >
          <ControlledInput
            name="nickname"
            control={control}
            label="Account Name"
            placeholder="e.g. MyCarrierAccount 01"
            statusText="The account name will be used to identify your account"
            required
          />

          <ControlledInput
            name="accountNumber"
            control={control}
            label="FedEx Account Number"
            placeholder="e.g. 670116009"
            required
          />

          <div className="grid grid-cols-2 w-full gap-5">
            <ControlledInput
              name="customerName"
              control={control}
              label="Company Name"
              required
            />

            <ControlledInput name="name" control={control} label="Name" />
          </div>

          <div className="grid grid-cols-2 w-full gap-5">
            <ControlledInput
              name="phoneNumber"
              control={control}
              label="Phone Number"
              required
            />

            <ControlledInput
              name="email"
              control={control}
              label="Email"
              type="email"
              required
            />
          </div>

          <Alert>
            <p>
              The address below should be the same as your current FedEx account
              billing address.
            </p>
          </Alert>

          <div className="grid grid-cols-2 w-full gap-5">
            {countries && (
              <ControlledSelect
                control={control}
                name="countryCode"
                label="Country"
                options={countries.map((country) => {
                  return {
                    value: country.alpha2,
                    label: country.name,
                  };
                })}
                placeholder="Choose a country"
                required
              />
            )}

            <ControlledInput
              name="postalCode"
              control={control}
              label="Zip Code"
              required
            />
          </div>
          <div className="grid grid-cols-2 w-full gap-5">
            <ControlledInput
              name="city"
              control={control}
              label="City"
              required
            />

            <ControlledInput
              name="state"
              control={control}
              label="State/Province"
              required
            />
          </div>

          <ControlledInput
            name="line1"
            control={control}
            label="Address Line 1"
            required
          />

          <ControlledInput
            name="line2"
            control={control}
            label="Address Line 2"
          />

          <p className="text-xs mt-3 text-ink-100">
            FedEx service marks are owned by Federal Express Corporation and are
            used by permission.
          </p>
        </Form>
      )}
    </>
  );
};
