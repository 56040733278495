import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { Courier, CourierResponse } from "@/models/Courier";
import axios from "axios";
import { useAuthContext } from "@/providers/AuthProvider";

// All couriers - use for testing
// const couriers = (await apiClient.dashboardApi(publicApiToken).GET('/api/v1/couriers')).data;

export const useCourierAccounts = (
  companyId: string | undefined
): UseQueryResult<Courier[]> => {
  const { credentials } = useAuthContext();

  return useQuery<Courier[]>({
    enabled: !!(credentials && companyId),
    queryKey: ["courier-accounts"],
    queryFn: async (): Promise<Courier[]> => {
      const { data } = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts`
      );

      return (
        data?.easyship_courier_accounts.map((courier: CourierResponse) => {
          return {
            id: courier.id,
            umbrellaName: courier.umbrella_name,
            alpha2: courier.origin_country_alpha2,
            logoUrl: courier.logo_url,
          };
        }) || []
      );
    },
    staleTime: Infinity,
  });
};
