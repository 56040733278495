import { z } from "zod";

export const formSchema = z.object({
  accountNumber: z.string().min(1, "Required field"),
  nickname: z.string().min(1, "Required field"),
  customerName: z.string().min(1, "Required field"),
  obaEmailAddress: z.string().min(1, "Required field"),
  intersoftPostingLocation: z
    .string()
    .length(10, "Field should contain 10 digits")
    .startsWith("9", "Invalid format"),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
