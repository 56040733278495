export const courierLogo = (logoUrl: string) => {
  return `${import.meta.env.VITE_APP_STORAGE}/courier-logos/${logoUrl || "nonbranded"}-mini.svg`;
};

export const courierUmbrellaName: {
  [key: string]: string;
} = {
  apg: "APG",
  aramex: "Aramex",
  "australia-post": "Australia Post",
  "australia-post-mypost-business": "Australia Post MyPost Business",
  "australia-post-on-demand": "Australia Post On Demand",
  "canada-post": "Canada Post",
  "couriers-please": "CouriersPlease",
  dhl: "DHL",
  "dhl-ecommerce": "DHL eCommerce",
  dpex: "DPEX",
  fastway: "Fastway",
  fedex: "FedEx",
  "fedex-cross-border": "FedEx Cross Border",
  janio: "Janio",
  lasership: "Lasership",
  "on-trac": "OnTrac",
  "royal-mail": "Royal Mail",
  seko: "Seko",
  sendle: "Sendle",
  skypostal: "Skypostal",
  "star-track": "StarTrack",
  tnt: "TNT",
  ups: "UPS®️",
  passport: "Passport",
};
