import React from "react";

export interface LayoutProps {
  title: string;
  leadingElement?: React.ReactNode;
  children: React.ReactNode;
}

export const Layout = ({
  title,
  leadingElement,
  children,
}: LayoutProps): React.ReactElement => (
  <div className="text-ink-900 text-base h-full w-full min-w-[404px] max-w-[704px] box-border p-2 flex flex-col">
    <div className="relative grow">
      <div className="flex gap-3 items-center mb-5">
        {leadingElement}
        <h1 className="text-xl">{title}</h1>
      </div>
      {children}
    </div>

    <img className="mt-5" src="/powered_by.svg" />
  </div>
);
