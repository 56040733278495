import { useToken } from "@/hooks/useToken";
import { createContext, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import { routesList } from "@/router/routes-list";

interface AuthContextProps {
  credentials: string | undefined;
}

export const AuthContext = createContext<AuthContextProps>({
  credentials: undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    if (!token)
      navigate(routesList.error, {
        replace: true,
        state: "You don't have permissions to access this content.",
      });
  }, []);

  const { data: authToken } = useToken(token || "");

  if (authToken) {
    const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;
    Cookies.set(credentialsEnv, authToken);
  }
  return (
    <AuthContext.Provider value={{ credentials: authToken }}>
      <Outlet />
    </AuthContext.Provider>
  );
};
