import React from "react";
import { routesList } from "@/router/routes-list";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "easyship-components";
import { LayoutError } from "@/components/LayoutError";

/**
 * This component is used as an error display page,
 * It is used normally on the router also as an error boundary fallback,
 * thats the reason for using the error boundary props
 */

export const ErrorPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;

  return (
    <LayoutError title="We encountered an error.">
      <p className="text-center">
        {state?.message ||
          "Please retry or contact support for assistance if the issue persists."}
      </p>
      {!state?.hideButton && (
        <Button
          className="mt-6"
          color="primary"
          onClick={() => {
            navigate(routesList.home, {
              replace: true,
            });
          }}
        >
          Retry
        </Button>
      )}
    </LayoutError>
  );
};
