import { FormValues, formSchema } from "./schema";
import { CourierFormComponentProps } from "../types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/Form";
import { ControlledInput } from "@/components/ControlledInput";

export const COMPATIBLE_UMBRELLA_NAMES = {
  auPostBusiness: "Australia Post MyPost Business",
  auPostDemand: "Australia Post On Demand",
} satisfies Record<string, string>;

export const FormComponent = ({
  umbrellaName,
  onSuccess,
}: CourierFormComponentProps<FormValues>) => {
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  return (
    <Form onSubmit={handleSubmit(onSuccess)}>
      {umbrellaName === COMPATIBLE_UMBRELLA_NAMES.auPostBusiness && (
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
        />
      )}

      <ControlledInput
        name="accountNumber"
        control={control}
        label="Account Number"
        placeholder="e.g. 123456789"
      />

      <ControlledInput
        name="password"
        control={control}
        label="Password"
        type="password"
      />

      <ControlledInput
        name="userId"
        control={control}
        label="Australia Post API Key"
      />
    </Form>
  );
};
