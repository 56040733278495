import { COMPATIBLE_UMBRELLA_NAMES } from "./FedexForm";
import { generatePath, useNavigate } from "react-router-dom";
import { routesList } from "@/router/routes-list";
import { Separator } from "@/components/Separator";
import { Button } from "easyship-components";

interface PdfAgreementProps {
  umbrellaName: string;
  acceptAgreement: () => void;
}

export const PdfAgreement = ({
  umbrellaName,
  acceptAgreement,
}: PdfAgreementProps) => {
  const navigate = useNavigate();

  const getFedexEulaPdf = () => {
    let fileName = "fedex-eula-distribution-agreement";

    if (umbrellaName === COMPATIBLE_UMBRELLA_NAMES.fedexCrossBorder) {
      fileName = "fedex-cross-border-eula";
    } else {
      fileName = "fedex-eula-v2";
    }

    return `${import.meta.env.VITE_APP_STORAGE}/courier-docs/${fileName}.pdf`;
  };

  const goBack = () => {
    navigate(generatePath(routesList.courierList));
  };

  return (
    <>
      <p className="mb-4">
        In order to continue, you will need to agree to the FedEx EULA.
      </p>
      <iframe
        className="h-[380px] w-full"
        src={`${getFedexEulaPdf()}#zoom=FitW`}
      ></iframe>
      <p className="text-xs mt-3 text-ink-500 italic mb-40">
        FedEx service marks are owned by Federal Express Corporation and are
        used by permission.
      </p>
      <Separator />

      <div className="flex justify-between w-full">
        <Button flat onClick={goBack}>
          back
        </Button>
        <Button color="primary" onClick={acceptAgreement} className="px-10">
          I accept
        </Button>
      </div>
    </>
  );
};
