import { Card } from "easyship-components";

interface CourierCardProps {
  logo?: string;
  name: string;
  onClick?: () => void;
}

export const CourierCard = ({ logo, name, onClick }: CourierCardProps) => {
  return (
    <Card
      variant="normal"
      className="flex items-center px-6 py-5 h-[84px] gap-2 my-3"
      onClick={onClick}
    >
      <img src={logo} alt={`${name} logo`} className="w-[54px]" />
      <p className="text-ink-500">{name}</p>
    </Card>
  );
};
