import { z } from "zod";

export const formSchema = z.object({
  nickname: z.string().min(1, "Required field"),
  accountNumber: z.string().min(1, "Required field"),
  customerName: z.string().min(1, "Required field"),
  name: z.string().min(1, "Required field"),
  phoneNumber: z.string().min(1, "Required field"),
  email: z.string().min(1, "Required field"),
  countryCode: z.string().min(1, "Required field"),
  postalCode: z.string().min(1, "Required field"),
  city: z.string().min(1, "Required field"),
  state: z.string().min(1, "Required field"),
  line1: z.string().min(1, "Required field"),
  line2: z.string().optional(),
});

export const validate = (data: unknown) => {
  return formSchema.parse(data);
};

export type FormValues = z.infer<typeof formSchema>;
