import {
  DefaultError,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import axios from "axios";
import { Courier } from "@/models/Courier";
import * as CC from "change-case";
import { generatePath, useNavigate } from "react-router-dom";
import { routesList } from "@/router/routes-list";
import { postMessage } from "@/helpers/postMessage";

interface SubmitCourierAccountData {
  total_couriers_count?: number;
  couriers?: Courier[];
}

export type UseSubmitCourierMutationResult = UseMutationResult<
  SubmitCourierAccountData,
  DefaultError,
  {
    companyId: string;
    courier: string;
    data: object;
  }
>;

export const useSubmitCourierMutation = (): UseSubmitCourierMutationResult => {
  const navigate = useNavigate();
  return useMutation<
    SubmitCourierAccountData,
    DefaultError,
    {
      companyId: string;
      courier: string;
      data: object;
    }
  >({
    mutationFn: async ({ companyId, courier, data }) => {
      const formattedData: { [k: string]: string } = {};
      Object.keys(data).map((key) => {
        const keyWithNumber = key.split(/(\d+)/).join("_");
        const formattedKey = CC.snakeCase(keyWithNumber);
        return (formattedData[formattedKey] = data[key as keyof typeof data]);
      });

      return await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/v1/companies/${companyId}/courier_accounts/${courier}`,
        { account: formattedData }
      );
    },
    onError: (error) => {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.status
        : "An error has occurred while adding the courier account";
      postMessage("error", message);
      navigate(routesList.error, {
        state: { message },
      });
    },
    onSuccess: (_, variables) => {
      postMessage("success", "Courier account added successfully");
      navigate(routesList.success, { state: { courier: variables.courier } });
    },
  });
};
